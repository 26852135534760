import "../../common/global.css";
import "./PassRate.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Card,
  Button,
  Notification
} from "@cambridgeassessment/cambridge-ui";
import { constants } from "../../constants";
import { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Spinner from "../../common/Spinner";
import { getDistrictList, getDistrictOrStateTotal, getDistrictState, getPassRateData, getSchoolsData } from "../../services/muleSoftServices";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { GrpDropDown, DropDown } from "../../common/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { setAlert } from "../../redux/slice/GlobalMessage";
import { SvgIcon } from "@mui/material";
import { setTabNumber } from "../../redux/slice/sideNavigation";
import { setCentreNavs, setDistrictNavs, setStateNavs } from "../../redux/slice/passRate";
import { setSchoolsData } from "../../redux/slice/dropDownsData";
import { setDistrictAndStateIds, setDistrictAndStateNames } from "../../redux/slice/userInfo";
import { showPendingNotification, stringSorting } from "../../common/validations";

const PassRate = () => {
  const initialState = {
      centreDetails: {},
      spin: true,
      seriesSelectedValue: {},
      qualificationSelectedValue: {},
      schoolSelectedValue:{},
      districtSelectedValue:{},
      dTRow:{}
    },
    [initialData, setInitialData] = useState(initialState),
    [sort, setSort] = useState({ key: "passRate", ascending: true, previousKeyChange: false  }),
    [exams, setExams] = useState([]),
    [schoolRes,setSchoolRes] = useState([]),
    [districtData,setDistrictData] = useState([]),
    [centreSortDistOnly,setCentreSortDistOnly] = useState([]),
    [stateData,setStateData] = useState([]),
    [distDropList,setDistDropList] = useState([]),
    [sDistrictData,setSdistrictdata] = useState([]),
    [disCentres,setDisCentres] = useState([]),    
    initialized = useRef(false),
    seriesList = useRef([]),
    dispatch = useDispatch(),
    location = useLocation(),
    navigate = useNavigate(),
    savedResp = useSelector((state) => state.pass),
    userInfo = useSelector((state) => state.userInfo),
    supportRole = useSelector((state) => state.new),
    passrateRef = useRef(null),
    headerLabel = useRef(""),
    downloadBtn = useRef(""),
    spinnerRef = useRef(""),
    notificationStatus = useRef("pending"),
    dropSelection = useRef(false),
    swapToExam = useRef(false),
    swapStateToDistrict = useRef(false),
    swapDistrictToCentre = useRef(false),
    schoolOptionsEnable = useRef(false),
    selectedCentre = useRef(""),
    districtsViewInStateRole = useRef(false),
    centresViewInStateRole = useRef(false),
    syllabusViewInStateRole = useRef(false),
    {totalLoggedInData,roleFromSAP,roleLevel,orgs,smallScreen,
      emlCentreObj,emlDistrictObj,emlStateObj,uid,state,hierarchy,
      emlCentre,emlDistrict,emlState,stateName,district} = userInfo,
    tabChange = (s) => {
      dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:s.tab}));
      navigate(s.path);
    };

  useEffect(() => {
    window.scrollTo(0,0);
    let firstSeries,qual;
    if(!isEmpty(savedResp.seriesLabels) && !initialized.current && !isEmpty(savedResp.seriesDropList)){
      initialized.current = true;
      seriesList.current = savedResp.seriesDropList;
      if(savedResp.districtNavs?.length === 2){
        let dNavs = [],modifiedParams;
        const {params} = savedResp.districtNavs[1];
        if(roleFromSAP.toLowerCase().includes("district") || (supportRole.support && emlDistrict)){
          swapToExam.current = true;
        }
        selectedCentre.current = params.centreId;
        const {passRateReport} = constants.sideNav.roleBaseApiKeys;
        
        if(roleLevel === "support" && emlCentre){
          modifiedParams = {
            centreId:emlCentreObj.centreId
          }
        }else if(roleLevel === "support" && emlDistrict){
          modifiedParams = {
            districtId:emlDistrictObj.districtId,
            roleLevel:emlDistrictObj.emlLevel
          }
        }else if(roleLevel === "support" && emlState){
          modifiedParams = {
            roleLevel:emlStateObj.emlLevel
          }
        }
       
        
        const backParams = { token:totalLoggedInData.access_token,
                              roleName:roleFromSAP,
                              centreId:modifiedParams?.centreId?modifiedParams.centreId:params.centreId,
                              series:[params.series],
                              quals:[params.qual],
                              bpId:orgs[0]?.bpid,
                              districtId:district,
                              stateCode:state,
                              roleLevel:passRateReport.exams};
        getPassRateForDisToExamSwap(backParams);
        dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:1}));
        dNavs.push(savedResp.districtNavs[0]);
        dispatch(setDistrictNavs({districtNavs:dNavs}));
        let qual = constants.dropDownList.qualification.filter(q=>q.label === params.qual),
            series = seriesList.current.filter(s=>s.label === params.series);
        retrieveSchools();
        setInitialData((previousState)=>{
          return {...previousState,qualificationSelectedValue:qual[0],
                     seriesSelectedValue:series[0]}
        })
      }else{      
          if(location?.state?.level){
            qual = constants.dropDownList.qualification.filter(q=>q.value === location.state.level);
            firstSeries = savedResp.seriesDropList?.filter(s=>s.label === location.state.series);        
          }
          else{
            qual = constants.dropDownList.qualification;
            firstSeries = savedResp.seriesDropList?.filter(s=>s.label === savedResp.seriesLabels[0]);                      
          }
          setInitialData((previousState)=>{
            return {...previousState,qualificationSelectedValue:qual[0],seriesSelectedValue:firstSeries[0]}
          })      
          getExamsData(firstSeries[0].label,qual[0].label);
      }
    }      
      
    if(roleLevel === 'district' || (supportRole.support && emlDistrict)){
      headerLabel.current = constants.passRate.districtHeader;
      downloadBtn.current = "Go to data downloads";
    }  
    if(roleLevel === 'exams' || roleLevel === 'head' || (supportRole.support && emlCentre)){
      headerLabel.current = constants.passRate.examHeader;
      downloadBtn.current = "Go to data downloads";
    }
    if(roleLevel === 'state' || (supportRole.support && emlState)){
      headerLabel.current = constants.passRate.stateHeader;
      downloadBtn.current = "Go to data downloads";
    }
      // eslint-disable-next-line
  }, []);
  
  const dataSort = (params) => {
    setSort((previousState) => {
      return { ...previousState,
              key: params.key,
              ascending: params.ascending,
              previousKeyChange: previousState.key !== params.key };
    });    
  }

  useEffect(() => {
    if(!isEmpty(exams)){
      const examsCopy = [...exams],
            sortedExams = examsCopy.sort((a, b) => { return a[sort.key] - b[sort.key]; });
      setExams(sort.ascending ? sortedExams : sortedExams.reverse());
    }else if(!isEmpty(districtData)){
        let sortedDisSchools;
        if(sort.key !== "centreID"){
          const districtCopy = [...districtData];
          sortedDisSchools = [...districtCopy].sort((a, b) => { return a[sort.key] - b[sort.key]; });
          setDistrictData(sort.ascending ? sortedDisSchools : sortedDisSchools.reverse());
        }else{
          if(districtData[0].centreID !== centreSortDistOnly[0].centreID){
            setDistrictData(centreSortDistOnly);
          }else{
            setDistrictData([]);
            setDistrictData(centreSortDistOnly.reverse());
          }
        }    
    }else if(!isEmpty(stateData) || swapDistrictToCentre.current){
      let stateCopy,sortedState;
      if(!swapStateToDistrict.current && !swapDistrictToCentre.current){
        stateCopy = [...stateData];        
        sortedState = sortingData((!districtsViewInStateRole.current || sort.previousKeyChange), stateCopy);
        if(!districtsViewInStateRole.current) districtsViewInStateRole.current = true;
        setStateData(sortedState);
      }else if(swapStateToDistrict.current && sDistrictData.length>0){
        stateCopy = [...sDistrictData];
        sortedState = sortingData(sort.key !== "centreID"?(!centresViewInStateRole.current || sort.previousKeyChange):false, stateCopy);
        if(!centresViewInStateRole.current) centresViewInStateRole.current = true;
        setSdistrictdata(sortedState);
      }else if(swapDistrictToCentre.current && disCentres.length>0){
        stateCopy = [...disCentres];        
        sortedState = sortingData((!syllabusViewInStateRole.current || sort.previousKeyChange), stateCopy);
        if(!syllabusViewInStateRole.current) syllabusViewInStateRole.current = true;
        setDisCentres(sortedState);
      }
    }
  }, [sort]);

  const sortingData = (sortAscend,stateCopy) => {
    let dataSorted = sortAscend?
                     [...stateCopy].sort((a, b) => { return a[sort.key] - b[sort.key] }):
                     [...stateCopy].reverse();
    return dataSorted;
  }

  useEffect(()=>{
    if(dropSelection.current && !swapStateToDistrict.current && !swapDistrictToCentre.current){
      dropSelection.current = false;
      getExamsData(initialData.seriesSelectedValue.label,initialData.qualificationSelectedValue.label);
    }
  },[dropSelection.current]);

  const getExamsData = async (seriesId,qual) => {
    let modifiedParams,selectedSeries,selectedQual;
    const {passRateReport} = constants.sideNav.roleBaseApiKeys;

    if(roleLevel === "support" && emlCentre){
      modifiedParams = {
        centreId:emlCentreObj.centreId
      }
    }else if(roleLevel === "support" && emlDistrict){
      modifiedParams = {
        districtId:emlDistrictObj.districtId,
        roleLevel:emlDistrictObj.emlLevel
      }
    }else if(roleLevel === "support" && emlState){
      modifiedParams = {
        roleLevel:emlStateObj.emlLevel
      }
    }
   
    const params = {token:totalLoggedInData.access_token,
                    roleName:roleFromSAP,
                    centreId:supportRole.support?modifiedParams.centreId:orgs[0]?.sourceSystems[0]?.orgId,
                    series:[seriesId],
                    quals:[qual],
                    bpId:orgs[0]?.bpid,
                    roleLevel:modifiedParams?.roleLevel?modifiedParams.roleLevel:passRateReport[roleLevel],
                    districtId:modifiedParams?.districtId?modifiedParams.districtId:district,
                    stateId:stateName,
                    stateCode:state},
    examDetails = await getPassRateData(params);    
    if(examDetails?.error){
      dispatch(setAlert({message:constants.alertMsgs[examDetails.message],status:examDetails.type}));      
    }else if(!examDetails?.data?.dataRows.length){
      notificationStatus.current = showPendingNotification(seriesId);
      setDistrictData([]);
      setExams([]);
      setSchoolRes([]);
      setStateData([]);
      spinnerRef.current?.spinnerOpen();
        setInitialData((previousState) => {
          return {...previousState,spin: false};
      });
    }else{
        const examsCopy = examDetails?.data?.dataRows ? [...examDetails?.data?.dataRows]:[],
          sortedExams = [...examsCopy].sort((a, b) => {
            return a[sort.key] - b[sort.key];
          });
        if((roleLevel === "exams" || emlCentre) && !swapToExam.current){
          setExams(sortedExams);
        }else if(roleLevel === "head" && !swapToExam.current){
          setExams(sortedExams);
        }
        else if((roleLevel === "district" || emlDistrict) && !swapToExam.current){
          selectedSeries = seriesList.current.filter(s=>s.label === seriesId);
          selectedQual = constants.dropDownList.qualification.filter(q=>q.label === qual);
          setDistrictData(examsCopy);
          setCentreSortDistOnly(examsCopy);
          setExams([]);
          setSchoolRes([]);
          setInitialData((previousState) => {
            return {...previousState,
                    seriesSelectedValue:selectedSeries[0],
                    qualificationSelectedValue:selectedQual[0]};
          });
          if(examsCopy?.length>0){
            districtOrStateTotalRow(seriesId,qual);
          }      
        }               
        else if(roleLevel === "state" || emlState){
          setStateData(stringSorting(sortedExams,{ascending:true,key:"districtName"}));
          if(sortedExams?.length>0){        
            districtOrStateTotalRow(seriesId,qual);
          }
        }
        setTableData(examDetails);
    }    
    if(roleLevel === "exams" || emlCentre){
      spinnerRef.current?.spinnerOpen();
        setInitialData((previousState) => {
          return {...previousState,spin: false};
        });
    }
  }  

  const setTableData = (examDetails) => {
    if(examDetails?.data){
      const { centreID, centreName, series, qual,
              stateCode, countryCode, centreState } = examDetails.data;
      spinnerRef.current?.spinnerOpen();
      setInitialData((previousState) => {
        return {
          ...previousState,
          centreDetails: { centreID, centreName, series, qual,
                           stateCode, countryCode, centreState },
          spin: false,
          dTRow:!examDetails.data?.dataRows.length?{}:initialData.dTRow
        };
      });
    }
  };

  const selectedOption = (key, li) => {
    let req;
    const {passRateReport} = constants.sideNav.roleBaseApiKeys;
    if((initialData[key]?.label && initialData[key].label === li?.label) ||
        initialData[key]?.centreID && initialData[key].centreID === li?.centreID ||
        initialData[key]?.districtID && initialData[key].districtID === li?.districtID){
          return;
    }

    if(key === "districtSelectedValue" && (swapStateToDistrict.current || swapDistrictToCentre.current)){
      if(swapDistrictToCentre.current){
        retrieveSchools(li?.districtID);
      }else{
        req = {
          series:[initialData.seriesSelectedValue.label],
          qual:[initialData.qualificationSelectedValue.label],
          districtId:li.districtID,
          roleName:roleFromSAP,
          roleLevel:swapStateToDistrict.current?passRateReport.district:passRateReport.exams
        }        
      }
      headerLabel.current = constants.passRate.stateHeaderForDistrict+" - "+li.districtName.toUpperCase()              
    }else if(key === "schoolSelectedValue" && swapDistrictToCentre.current){
      req = {
        series:[initialData.seriesSelectedValue.label],
        qual:[initialData.qualificationSelectedValue.label],
        districtId:initialData.districtSelectedValue?.districtID,
        centreId:li.centreID,
        roleName:roleFromSAP,
        roleLevel:passRateReport.exams
      }
    }else if(key === "seriesSelectedValue" && (swapStateToDistrict.current || swapDistrictToCentre.current)){
      req = {
        series:[li.label],
        qual:[initialData.qualificationSelectedValue.label],
        districtId:initialData.districtSelectedValue?.districtID,
        centreId:swapDistrictToCentre?initialData.schoolSelectedValue.centreID:"",
        roleName:roleFromSAP,
        roleLevel:swapStateToDistrict.current?passRateReport.district:passRateReport.exams
      }
    }else if(key === "qualificationSelectedValue" && (swapStateToDistrict.current || swapDistrictToCentre.current)){
      req = {
        series:[initialData.seriesSelectedValue.label],
        qual:[li.label],
        districtId:initialData.districtSelectedValue?.districtID,
        centreId:swapDistrictToCentre?initialData.schoolSelectedValue.centreID:"",
        roleName:roleFromSAP,
        roleLevel:swapStateToDistrict.current?passRateReport.district:passRateReport.exams
      }
    }
    else if(key === "schoolSelectedValue"){
      req = {
        series:[initialData.seriesSelectedValue.label],
        qual:[initialData.qualificationSelectedValue.label],
        centreId:li.centreID,
        roleName:roleFromSAP,
        roleLevel:passRateReport.exams
      }
      selectedCentre.current = li.centreID;
    }else if(key === "seriesSelectedValue" && swapToExam.current){
      req = {
        series:[li.label],
        qual:[initialData.qualificationSelectedValue.label],
        centreId:initialData.schoolSelectedValue?.centreID,
        roleName:roleFromSAP,
        roleLevel:passRateReport.exams
      }
    }else if(key === "qualificationSelectedValue" && swapToExam.current){
      req = {
        series:[initialData.seriesSelectedValue.label],
        qual:[li.label],
        centreId:initialData.schoolSelectedValue?.centreID,
        roleName:roleFromSAP,
        roleLevel:passRateReport.exams
      }
    }

    if(!isEmpty(req)){
      getPassRateOnDistrictOrCentreSelection(req,key);      
    }else{
      if(key === "seriesSelectedValue" || key === "qualificationSelectedValue" || 
        (swapDistrictToCentre.current && key === "districtSelectedValue")){
        dropSelection.current = true;
      }
    }    
    setInitialData((previousState) => {
      return { ...previousState, [key]: li, spin:true };
    });        
  };

  const getPassRateOnDistrictOrCentreSelection = async (req,key) =>{
    const params = {token:totalLoggedInData.access_token,
                    roleName:req.roleName,
                    centreId:req?.centreId?req.centreId:"",
                    series:req.series,
                    quals:req.qual,
                    bpId:orgs[0]?.bpid,
                    districtId:req?.districtId?req.districtId:district,
                    stateCode:state,
                    roleLevel:req.roleLevel},
    examDetails = await getPassRateData(params);
    if(examDetails?.error){
      dispatch(setAlert({message:constants.alertMsgs[examDetails.message],status:examDetails.type}));      
    }else if(!examDetails?.data?.dataRows.length){
      notificationStatus.current = showPendingNotification(req.series[0]);
      setExams([]);
      setSdistrictdata([]);
      setDisCentres([]);
    }else{
        const examsCopy = examDetails?.data?.dataRows ? [...examDetails?.data?.dataRows]:[],
              sortedExams = examsCopy.sort((a, b) => {
                return a[sort.key] - b[sort.key];
              });
        if(swapToExam.current){
          setExams(sortedExams);
        }else if(swapStateToDistrict.current){
          setSdistrictdata(sortedExams);
          if(sortedExams.length>0){
            selectedCentre.current = sortedExams[0].centreID;
            if((key === "districtSelectedValue" || key === "seriesSelectedValue" || key === "qualificationSelectedValue") && 
                swapStateToDistrict.current){
              districtOrStateTotalRow(req.series,req.qual,req.districtId)
            }
          }
        }else if(swapDistrictToCentre.current){
          setDisCentres(sortedExams);
        }        
    }      
    setInitialData((previousState) => {
      return { ...previousState, spin:false };
    });    
  }
  
  const downloadPdf = useReactToPrint({
    bodyClass:"m-2 p-2",
    documentTitle:'Pass_Rate_Report',
    content: () => passrateRef.current
  });
  
  const districtOrStateTotalRow = async(seriesId,qual,dId) =>{
    let modifiedParams;
    const {districtTotal} = constants.sideNav.roleBaseApiKeys;

    if(roleLevel === "support" && emlDistrict){
      modifiedParams = {
        districtId:emlDistrictObj.districtId,
        roleLevel:emlDistrictObj.emlLevel
      }
    }else if(roleLevel === "support" && emlState){
      modifiedParams = {
        roleLevel:emlStateObj.emlLevel
      }
    }

    const params = {token:totalLoggedInData.access_token,
                    roleName:roleFromSAP,
                    series:seriesId,
                    quals:qual,
                    bpId:orgs[0]?.bpid,
                    roleLevel:(modifiedParams?.roleLevel && !swapStateToDistrict.current)?modifiedParams.roleLevel:
                              swapStateToDistrict.current?districtTotal.district:districtTotal[roleLevel],
                    districtId:(modifiedParams?.districtId && !swapStateToDistrict.current)?modifiedParams.districtId:
                               dId?dId:district,
                    stateId:state},

    tData = await getDistrictOrStateTotal(params);
    if(tData?.data.length>0){
      setInitialData((previousState)=>{
        return{...previousState,spin:false,dTRow:tData.data[0]}
      })
    }else{
      setInitialData((previousState)=>{
        return{...previousState,spin:false}
      })
    }
  }

  const distOrStateTotFormat = (dTRow) =>{
    return(
      <TableRow style={{backgroundColor:"#f2f2f2"}}>
        <TableCell><b>Total</b></TableCell>
        <TableCell>
          <div className="progress p_bar_rad h-100">
            <div style={{ height: "100%", width: ((dTRow?.passrate<20 && dTRow?.passrate>0) || (smallScreen && dTRow?.passrate>0))? "auto":`${dTRow?.passrate}%` }}
                  className="progress-bar head_bg_blue bar_text"
                  role="progressbar">
                <span className="mx-1">{dTRow?.passrate}%</span>
            </div>
          </div> 
        </TableCell>
        <TableCell className="bg_grey text-center" style={{ width:"100px"}}><b>{dTRow?.exams}</b></TableCell>
        <TableCell className="val_bg_blue text-center" style={{ width:"100px"}}><b>{dTRow?.passrate}%</b></TableCell>                      
      </TableRow>
    )
  }

  useEffect(()=>{
    spinnerRef.current?.spinnerOpen();
   },[initialData.spin])

  const syllabusTabChange = (s) =>{
    let dNavs = [],cId='';
    if(savedResp.districtNavs?.length>0){
      dNavs.push(savedResp.districtNavs[0]);
    }
    if(emlCentre){
      cId = emlCentreObj.centreId;
    }
    dNavs.push({step:s.step,params:{series:s.series,
                                    sylId:s.sylID,
                                    qual:initialData.qualificationSelectedValue.label,
                                    reDirectTo:"/pass-rates",
                                    centreId:cId?cId:selectedCentre.current?selectedCentre.current:
                                             orgs[0]?.sourceSystems[0]?.orgId }})
    dispatch(setDistrictNavs({districtNavs:dNavs}));
    dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:s.tab}));
    navigate(s.path,{state:s});
  }

  const districtToExamSwap = (centreId,step) =>{
    window.scrollTo(0,0);
    const {seriesSelectedValue,qualificationSelectedValue} = initialData;
    let dNavs = [];
    swapToExam.current = true;
    selectedCentre.current = centreId;
    dNavs.push({step:step,params:{series:seriesSelectedValue.label,qual:qualificationSelectedValue.label}})
    dispatch(setDistrictNavs({districtNavs:dNavs}));
    const {passRateReport} = constants.sideNav.roleBaseApiKeys,    
          params = {token:totalLoggedInData.access_token,
                    roleName:roleFromSAP,
                    centreId:centreId,
                    bpId:orgs[0]?.bpid,
                    series:[seriesSelectedValue.label],
                    quals:[qualificationSelectedValue.label],
                    districtId:district,
                    stateCode:state,
                    roleLevel:passRateReport.exams};
    retrieveSchools();
    getPassRateForDisToExamSwap(params);  
  }

  const getPassRateForDisToExamSwap = async(params) =>{
    const examDetails = await getPassRateData(params);
    if(examDetails?.error){
      dispatch(setAlert({message:constants.alertMsgs[examDetails.message],status:examDetails.type}));      
    }else if(!examDetails?.data?.dataRows.length){
      notificationStatus.current = showPendingNotification(params.series[0]);
      setExams([]);
      setDistrictData([]);
    }else{
      const examsCopy = examDetails?.data?.dataRows ? [...examDetails?.data?.dataRows]:[],
        sortedExams = examsCopy.sort((a, b) => {
          return a[sort.key] - b[sort.key];
        });    
      setExams(sortedExams);
      setDistrictData([]);
    }
    setInitialData((previousState)=>{
      return {...previousState,spin:false}
    })
  }

  const stateToDistrictSwap = (stateCode,dId,step,stateName) =>{
    window.scrollTo(0,0);
    setInitialData((previousState)=>{
      return {...previousState,spin:false}
    })
    const {seriesSelectedValue,qualificationSelectedValue} = initialData;
    let sNavs = [],stateParams;
    swapStateToDistrict.current = true;
    headerLabel.current = constants.passRate.stateHeaderForDistrict+" - "+stateName.toUpperCase();
    stateParams = {stateCode:stateCode,
                   district:dId,
                   series:seriesSelectedValue,
                   qual:qualificationSelectedValue}
    sNavs.push({step:step,params:stateParams})
    dispatch(setStateNavs({stateNavs:sNavs}));
    districtOrStateTotalRow(seriesSelectedValue.label,qualificationSelectedValue.label,dId);
    getDistrictListForState(stateParams);
  }

  const districtToCentreSwap = (cId,step) =>{
    window.scrollTo(0,0);
    setInitialData((previousState)=>{
      return {...previousState,spin:false}
    })
    const {seriesSelectedValue,qualificationSelectedValue,districtSelectedValue,centreDetails} = initialData;
    let cNavs = [];
    swapDistrictToCentre.current = true;
    swapStateToDistrict.current = false;
    headerLabel.current = constants.passRate.stateHeaderForDistrict+" - "+districtSelectedValue.districtName.toUpperCase()
    cNavs.push({step:step,params:{stateCode:centreDetails?.stateCode,
                                  district:districtSelectedValue?.districtID,
                                  series:seriesSelectedValue,
                                  qual:qualificationSelectedValue}})
    dispatch(setCentreNavs({centreNavs:cNavs}));
    getCentresListForDistrict(seriesSelectedValue.label,qualificationSelectedValue.label,cId);
  }

  const backFromDistrictNavs = ()=>{
    window.scrollTo(0,0);
    setInitialData((previousState)=>{
      return {...previousState,spin:true,schoolSelectedValue:{}}
    })
    if(savedResp.districtNavs?.length === 1){
      swapToExam.current = false;
      schoolOptionsEnable.current = false;
      getExamsData(savedResp.districtNavs[0].params.series,savedResp.districtNavs[0].params.qual);
      dispatch(setDistrictNavs({districtNavs:[]}));
    }else{
      dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:0}));
      navigate("/dashboard");
    }
  }

  const backFromDistrictToStateNav = () =>{
    window.scrollTo(0,0);
    setInitialData((previousState)=>{
      return {...previousState,spin:true}
    })
    if(savedResp.stateNavs?.length === 1){
      swapStateToDistrict.current = false;
      const {params} = savedResp.stateNavs[0];
      dispatch(setStateNavs({stateNavs:[]}));
      setInitialData((previousState)=>{
        return {...previousState,spin:false,seriesSelectedValue:params.series,qualificationSelectedValue:params.qual}
      })
      districtOrStateTotalRow(params.series.label,params.qual.label,params.district);
    }
    headerLabel.current = constants.passRate.stateHeader;
  }

  const backFromCentreToDistrict = () =>{
    window.scrollTo(0,0);
    swapDistrictToCentre.current = false;
    swapStateToDistrict.current = true;    
    if(savedResp.centreNavs?.length === 1){
      setSchoolRes([]);
      setDisCentres([]);
      const {params} = savedResp.centreNavs[0];
      dispatch(setCentreNavs({centreNavs:[]}));
      setInitialData((previousState)=>{
        return {...previousState,spin:true,seriesSelectedValue:params.series,qualificationSelectedValue:params.qual}
      })
      districtOrStateTotalRow(params.series.label,params.qual.label,params.district);
      getDistrictListForState(params);
    }
  }

  const getDistrictListForState = async(params) =>{
    const {districtList} = constants.sideNav.roleBaseApiKeys,
          {stateLevel} = constants.sideNav.roleBaseApiKeys.passRateReport,
          distParams = {token:totalLoggedInData.access_token,
                    roleName:roleFromSAP,
                    roleLevel:districtList[roleLevel],
                    stateCode:params.stateCode,
                    bpId:orgs[0]?.bpid},
          dList = await getDistrictList(distParams);
    if(dList?.data?.data){
      setDistDropList(dList.data.data);
      const selectedDistrict = dList.data.data.filter(d=>d.districtID === params.district),                
            passParams = {token:totalLoggedInData.access_token,
                          roleName:roleFromSAP,
                          series:[params.series.label],
                          quals:[params.qual.label],
                          roleLevel:stateLevel[roleLevel],
                          districtId:params.district,
                          bpId:orgs[0]?.bpid,
                          districtId:params.district,
                          stateCode: !isEmpty(state)?state:hierarchy[0].stateId},
      centreDetails = await getPassRateData(passParams);
      if(centreDetails?.data?.dataRows){
        setSdistrictdata(centreDetails.data.dataRows)
      }
      setInitialData((previousState)=>{
        return {...previousState,spin:false,districtSelectedValue:selectedDistrict[0]}
      })
      headerLabel.current = constants.passRate.stateHeaderForDistrict+" - "+selectedDistrict[0].districtName.toUpperCase()
    }
  }

  const getCentresListForDistrict = async(series,qual,cId) =>{
      selectedCentre.current = cId;
      if(dropSelection.current){
        dropSelection.current = false;
      }else{
        retrieveSchools();
      }      
      schoolOptionsEnable.current = true;
      const params = {token:totalLoggedInData.access_token,
                      roleName:roleFromSAP,
                      roleLevel:"centre",
                      series:[series],
                      quals:[qual],
                      centreId:cId,
                      bpId:orgs[0]?.bpid,
                      districtId:initialData.districtSelectedValue?.districtID,
                      stateCode:!isEmpty(hierarchy[0].stateId)?hierarchy[0].stateId:state},
      syllabusDetails = await getPassRateData(params);
      if(syllabusDetails?.data?.dataRows){
        setDisCentres(syllabusDetails.data.dataRows)
      }
      setInitialData((previousState)=>{
        return {...previousState,spin:false}
      })     
  }  

  const retrieveSchools = async (dId) =>{
    let selectedSchool=[],dist;
      const {schoolList} = constants.sideNav.roleBaseApiKeys,
            {districtSelectedValue,seriesSelectedValue,qualificationSelectedValue} = initialData;
      if(district){
        dist = district
      }else if(dId){
        dist = dId
      }else if(districtSelectedValue?.districtID){
        dist = districtSelectedValue.districtID
      }       
      const params = {token:totalLoggedInData.access_token,
                      roleName:roleFromSAP,
                      districtId:dist,
                      roleLevel:schoolList[roleLevel],
                      bpId:orgs[0]?.bpid,
                      stateId:state?state:hierarchy[0].stateId},
          schoolData = await  getSchoolsData(params),
          schoolArr = schoolData?.data?.data;
      if(!isEmpty(schoolArr)){
              selectedSchool = schoolArr.filter(a=>a.centreID === selectedCentre.current);                    
      setSchoolRes(schoolArr);
      setInitialData((previousState)=>{
        return {...previousState,
                spin:false,
                schoolSelectedValue:selectedSchool?.length>0?selectedSchool[0]:schoolArr[0]
                }
      })
      dispatch(setSchoolsData({ schoolData:schoolArr}));
      if(swapDistrictToCentre.current && dropSelection.current){        
        getCentresListForDistrict(seriesSelectedValue.label,
                                  qualificationSelectedValue.label,
                                  selectedSchool?.length>0?selectedSchool[0].centreID:
                                  schoolArr[0].centreID);
      }    
    }
  }  

  return (
    <div>
      {initialData.spin ?<Spinner ref={spinnerRef} />: 
        <div ref={passrateRef}>
          <div className="row">
            {roleLevel !== "exams" && !emlCentre &&
            <>
            <div className="row mt-4">
                <div className="col-lg-6 col-md-8 col-sm-12">
                    <div><label className="lbl text-start pass_lbl">{headerLabel.current}</label></div>                                                
                </div>
                <div className="col-lg-6 col-md-4 col-sm-12 mb-2">
                    <Button className={`${window.innerWidth > 767?'float-end':'mt-4 float-start'}`}  onClick={() => tabChange({tab:0,path:"/dashboard"})}>{downloadBtn.current}</Button>
                </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 text-start mt-2 mb-2">
              {(roleLevel === "state" || emlState ||
                    roleLevel === "district" || emlDistrict)? 
                    constants.passRate.stateAndDistrictSubHead:
                    constants.passRate.commonSubHead}{" "}
                <a target="_blank" href={constants.passRate.cambridgeGradesLink} className='link'>Guide to Cambridge grades</a>.
            </div>
            </>}

            {(roleLevel === "exams" || (supportRole.support && emlCentre)) &&
            <>
            <div className="row mt-4">
                <div className="col-lg-6 col-md-8 col-sm-12">
                    <div><label className="lbl text-start pass_lbl">{headerLabel.current}</label></div>                                                
                </div>
                <div className="col-lg-6 col-md-4 col-sm-12 mb-2">
                    <Button className={`${window.innerWidth > 767?'float-end':'mt-2 float-start'}`}  onClick={() => tabChange({tab:0,path:"/dashboard"})}>{downloadBtn.current}</Button>
                </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 text-start mt-2 mb-2">{constants.passRate.subHeader} <a target="_blank" href={constants.passRate.cambridgeGradesLink} className='link'>Guide to Cambridge grades</a>.</div>
            </>}
          </div>          
          <div className="row mb-4">
          {(roleLevel === 'district' || emlDistrict) && <div className="col-1 mb-2 mt-28 text-start">
              <Button variant="outlined" color="primary" size="large" onClick={backFromDistrictNavs}>
                <SvgIcon className="backIconcls">{constants.studentReports.StudentDetail.icon.back}</SvgIcon>
              </Button>
            </div>}
          {(roleLevel === 'state' || emlState) && swapStateToDistrict.current && <div className="col-1 mb-2 mt-28 text-start">
            <Button variant="outlined" color="primary" size="large" onClick={backFromDistrictToStateNav}>
              <SvgIcon className="backIconcls">{constants.studentReports.StudentDetail.icon.back}</SvgIcon>
            </Button>
          </div>}
          {(roleLevel === 'state' || emlState) && swapDistrictToCentre.current && <div className="col-1 mb-2 mt-28 text-start">
            <Button variant="outlined" color="primary" size="large" onClick={backFromCentreToDistrict}>
              <SvgIcon className="backIconcls">{constants.studentReports.StudentDetail.icon.back}</SvgIcon>
            </Button>
          </div>}
            {(roleLevel === 'state' || emlState) && (swapStateToDistrict.current || swapDistrictToCentre.current) && 
            <div className="col-lg-3 col-md-12 col-sm-12 mb-2 mt-2">
              <DropDown class="text-start"
                      label='DISTRICT'
                      userSelectedDropdown='districtSelectedValue'
                      selectedValue={initialData.districtSelectedValue?.districtID}
                      list={distDropList.length?distDropList:[]}
                      onChange={selectedOption}
                      option='districtID' value='districtName'/>
            </div>}
            {(roleLevel === 'district' || emlDistrict || roleLevel === 'state' || emlState) && 
             (swapToExam.current || swapDistrictToCentre.current) &&
            <div className="col-lg-3 col-md-12 col-sm-12 mb-2 mt-2">
              <DropDown class="text-start"
                      label='SCHOOL'
                      userSelectedDropdown='schoolSelectedValue'
                      selectedValue={initialData.schoolSelectedValue.centreID}
                      list={schoolRes.length?schoolRes:[]}
                      onChange={selectedOption}
                      option='centreID' value='centreName'
                      mixedStr={true}/>
            </div>}
            <div className="col-lg-3 col-md-12 col-sm-12 mb-2 mt-2">
              <GrpDropDown class="text-start" 
                           onChange={selectedOption}
                           selectedValue={initialData.seriesSelectedValue.label}
                           userSelectedDropdown='seriesSelectedValue'
                           label={constants.passRate.dropDownLabels.series} 
                           list={seriesList.current}
                           option='label' value='label' />
            </div>
            <div className={`col-md-12 col-sm-12 mb-2 mt-2 ${(swapToExam.current || (swapStateToDistrict.current || swapDistrictToCentre.current))?"col-lg-2":"col-lg-3"}`}>
              <DropDown class="text-start" 
                        onChange={selectedOption}
                        selectedValue={initialData.qualificationSelectedValue.value}
                        userSelectedDropdown='qualificationSelectedValue'
                        label={constants.passRate.dropDownLabels.qual}
                        list={constants.dropDownList.qualification}
                        option='value' value='label' />
            </div>            
          </div>
          {(exams.length>0 || districtData.length>0 || stateData.length>0) ?<Card className="card_spacing">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-12">
                {(emlDistrict || (emlState && swapDistrictToCentre.current) || 
                (swapDistrictToCentre.current && roleLevel === 'state') || roleLevel === "district") &&  
                <h3 className="card-header text-start mb-2">
                {!swapToExam.current && !emlState && roleLevel !== 'state' ? 'All schools':
                (!emlState && roleLevel !== 'state')?`${initialData.schoolSelectedValue.centreID} - ${initialData.schoolSelectedValue.centreName}`:""}

                {swapDistrictToCentre.current && `${initialData.schoolSelectedValue.centreID} - ${initialData.schoolSelectedValue.centreName}`+" , "+ `${initialData.districtSelectedValue.districtName}`}
                </h3>
                }
                {(roleLevel === "exams" || emlCentre || roleLevel === "head") && <h3 className="card-header text-start mb-2">{emlCentre?emlCentreObj.centreName:orgs[0]?.name}</h3>}
                {(roleLevel === 'state' || emlState) && !swapStateToDistrict.current && !swapDistrictToCentre.current && <h3 className="card-header text-start mb-2">{"All districts"}</h3>}
                {(roleLevel === 'state' || emlState) &&  swapStateToDistrict.current && <h3 className="card-header text-start mb-2">{"All schools"} - {initialData.districtSelectedValue?.districtName}</h3>}
                <p className="mb-4 text-start card-sub-head">
                  {initialData.qualificationSelectedValue?.label}
                </p>
              </div>
              <div className={`col-lg-4 col-md-4 col-sm-12 mb-4 ${window.innerWidth <768?"text-start":"text-end"}`}>
              {(exams.length>0 || districtData.length>0 || stateData.length>0) && <Button variant="outlined" className="h-auto" onClick={downloadPdf}>{constants.dashboard.downloadIconBlue} Download (pdf)</Button>}
              </div>
            </div>
            
            {exams.length>0 && !swapToExam.current && <TableContainer>
              <Table aria-label="pass rate table">
                <TableHead>
                  <TableRow className="no_border_bottom">
                    <TableCell colSpan={2}></TableCell>
                    <TableCell colSpan={3} style={{ textAlign: "center" }} className="bg_grey">
                      School
                    </TableCell>
                    <TableCell colSpan={3} style={{ textAlign: "center" }}>
                      National
                    </TableCell>
                  </TableRow>
                  <TableRow className="h_bottom">
                    {constants.passRate.tableSubHead.map((t) => {                     
                        return(<TableCell colSpan={t.colSpan} className={t.class}>
                          <TableSortLabel onClick={() => dataSort({key: t.col,ascending: !sort.ascending})}>
                            {t.label === "gtc" ? (<>&ge;C</>):
                             t.label === "gte" ? t.col === "passRate" ? (<label style={{color:"#fff"}}>&ge;E</label>):
                             (<>&ge;E</>):(t.label)}
                          </TableSortLabel>
                      </TableCell>);
                      
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {exams.length>0? exams.map((d) => (
                    <TableRow hover={true} key={d.sylID}>
                      <TableCell aria-label={d.sylID + " - " + d.sylName} 
                                 title={d.sylID + " - " + d.sylName} 
                                 className="wrap">
                        <a className="syl_link" onClick={()=>syllabusTabChange({tab:2,path:'/syllabus-reports',
                                                                                series:initialData.seriesSelectedValue?.label,
                                                                                sylID:d.sylID,
                                                                                qualification:initialData.qualificationSelectedValue?.label})}>{d.sylID + " - " + d.sylName}</a>
                      </TableCell>
                      <TableCell>
                        <div className="progress p_bar_rad">
                          <div style={{ height: "100%", 
                                        width: ((d.passRate<20 && d.passRate>0) || (smallScreen && d.passRate>0)) ? 
                                        "auto":`${d.passRate}%` }}
                               className="progress-bar head_bg_blue bar_text"
                               role="progressbar">
                              <span className="mx-1">{d.passRate}%</span>
                          </div>
                        </div>
                        <div className="progress mt-1 p_bar_rad">
                          <div style={{ height: "100%", 
                                        width: ((d.passRateC<20 && d.passRateC>0) || (smallScreen && d.passRateC<50 && d.passRateC>0)) ? 
                                        "auto":`${d.passRateC}%` }}
                               className="progress-bar bg-warning bar_text val_color_yellow"
                               role="progressbar">
                              <span className="mx-1">{d.passRateC}%</span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="bg_grey text-center">{d.exams}</TableCell>
                      <TableCell className="val_bg_yellow text-center">{Number(d.passRateC).toFixed(1)}%</TableCell>
                      <TableCell className="val_bg_blue text-center">{Number(d.passRate).toFixed(1)}%</TableCell>
                      <TableCell className="text-center">{Number(d.natPassRateC).toFixed(1)}%</TableCell>
                      <TableCell className="text-center">{Number(d.natPassRate).toFixed(1)}%</TableCell>
                    </TableRow>
                  )):<TableRow><TableCell colSpan={12} className='text-center'>{constants.passRate.emptyMsg}</TableCell></TableRow>}
                </TableBody>
              </Table>
            </TableContainer>}
            {districtData.length>0 && !swapToExam.current && <TableContainer>
            <Table aria-label="pass rate table" id="districtTable">
                <TableHead>                  
                  <TableRow className="h_bottom">
                    {constants.passRate.districtTableHeaders.map((t) => {
                      return(<TableCell className={t.class}>
                                <TableSortLabel onClick={() => dataSort({key: t.col,ascending: !sort.ascending})}>
                                  {t.label === "gte" ? (<label style={{color:"#fff"}}>&ge;E</label>) : (t.label)}
                                </TableSortLabel>                                                                
                            </TableCell>);
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>            
                  {!isEmpty(initialData.dTRow) && distOrStateTotFormat(initialData.dTRow)}
                  {districtData.length>0? districtData.map((d) => (                    
                      <TableRow hover={true} key={d.centreID}>                     
                      <TableCell aria-label={d.centreID + " - " + d.centreName} 
                                 title={d.centreID + " - " + d.centreName} 
                                 className="col-2">
                        <a className="syl_link" onClick={()=>districtToExamSwap(d.centreID,"step1")}><span className="d-inline-block text-truncate ds_syl_width">{d.centreID + " - " + d.centreName}</span></a>
                      </TableCell>
                      <TableCell className="col-8">
                        <div className="progress p_bar_rad h-100">
                          <div style={{ height: "100%", width: ((d.passRate<20 && d.passRate>0) || (smallScreen && d.passRate>0))?
                                        "auto":`${d.passRate}%` }}
                               className="progress-bar head_bg_blue bar_text"
                               role="progressbar">
                              <span className="mx-1">{d.passRate}%</span>
                          </div>
                        </div>                        
                      </TableCell>
                      <TableCell className="bg_grey text-center col-1">{d.exams}</TableCell>
                      <TableCell className="val_bg_blue text-center col-1">{Number(d.passRate).toFixed(1)}%</TableCell>                      
                      </TableRow>                      
                  )):<TableRow><TableCell colSpan={12} className='text-center'>{constants.passRate.emptyMsg}</TableCell></TableRow>}
                  {!isEmpty(initialData.dTRow) && distOrStateTotFormat(initialData.dTRow)}
                </TableBody>
              </Table>
            </TableContainer>}
            {exams.length>0 && swapToExam.current && <TableContainer>
              <Table aria-label="pass rate table">
                <TableHead>
                  <TableRow className="no_border_bottom">
                    <TableCell colSpan={2}></TableCell>
                    <TableCell colSpan={2} style={{ textAlign: "center" }} className="bg_grey">
                      School
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      National
                    </TableCell>
                  </TableRow>
                  <TableRow className="h_bottom">
                    {constants.passRate.districtBySchoolSubHeads.map((t) => {                     
                        return(<TableCell colSpan={t.colSpan} className={t.class}>
                            <TableSortLabel onClick={() => dataSort({key: t.col,ascending: !sort.ascending})}>
                              {t.label === "gtc" ? (<>&ge;C</>) : t.label === "gte" ? 
                               t.col === "passRate" ? (<label style={{textAlign:"center",color:"#fff"}}>&ge;E</label>):
                              (<>&ge;E</>) : (t.label)}
                            </TableSortLabel>
                      </TableCell>);
                      
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {exams.length>0? exams.map((d) => (
                    <TableRow hover={true} key={d.sylID}>
                      <TableCell aria-label={d.sylID + " - " + d.sylName} 
                                 title={d.sylID + " - " + d.sylName} 
                                 className="col-2">
                        <a className="syl_link" onClick={()=>syllabusTabChange({tab:2,path:'/syllabus-reports',
                                                                                series:initialData.seriesSelectedValue?.label,
                                                                                sylID:d.sylID,step:"step2",
                                                                                centreId:selectedCentre.current,
                                                                                qualification:initialData.qualificationSelectedValue?.label})}><span className="d-inline-block text-truncate" style={{width:"200px"}}>{d.sylID + " - " + d.sylName}</span></a>
                      </TableCell>
                      <TableCell className="col-7">
                        <div className="progress p_bar_rad h-100">
                          <div style={{ height: "100%", width: ((d.passRate<20 && d.passRate>0) || (smallScreen && d.passRate>0))?
                                        "auto":`${d.passRate}%` }}
                               className="progress-bar head_bg_blue bar_text"
                               role="progressbar">
                              <span className="mx-1">{d.passRate}%</span>
                          </div>
                        </div>                        
                      </TableCell>                      
                      <TableCell className="bg_grey text-center col-1">{d.exams}</TableCell>
                      <TableCell className="val_bg_blue text-center col-1">{Number(d.passRate).toFixed(1)}%</TableCell>
                      <TableCell className="text-center col-1">{Number(d.passRateC).toFixed(1)}%</TableCell>
                    </TableRow>
                  )):<TableRow><TableCell colSpan={12} className='text-center'>{constants.passRate.emptyMsg}</TableCell></TableRow>}
                </TableBody>
              </Table>
            </TableContainer>}           
            {stateData.length>0 && !swapStateToDistrict.current && !swapDistrictToCentre.current && <TableContainer>
            <Table aria-label="pass rate table" id="stateTable">
                <TableHead>                  
                  <TableRow className="h_bottom">
                    {constants.passRate.stateTableHeaders.map((t) => {
                      return(<TableCell className={t.class}>
                              <TableSortLabel onClick={() => dataSort({key: t.col,ascending: !sort.ascending})}>
                                  {t.label === "gte" ? (<label style={{textAlign:"center",color:"#fff"}}>&ge;E</label>) : (t.label)}
                              </TableSortLabel>
                            </TableCell>);
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>            
                  {stateData.length>0 ? !isEmpty(initialData.dTRow) && distOrStateTotFormat(initialData.dTRow):""}
                  {stateData.length>0? stateData.map((d) => (                    
                      <TableRow hover={true} key={d.districtId}>                     
                      <TableCell aria-label={d.districtName} 
                                 title={d.districtName} 
                                 className="col-2">
                        <a className="syl_link" onClick={()=>stateToDistrictSwap(initialData.centreDetails?.stateCode,d.districtId,"step1",d.districtName)}><span className="d-inline-block text-truncate" style={{width:"200px"}}>{d.districtName}</span></a>
                      </TableCell>
                      <TableCell className="col-8">
                        <div className="progress p_bar_rad h-100">
                          <div style={{ height: "100%", width: ((d.passRate<20 && d.passRate>0) || (smallScreen && d.passRate>0))?
                                       "auto":`${d.passRate}%` }}
                               className="progress-bar head_bg_blue bar_text"
                               role="progressbar">
                              <span className="mx-1">{d.passRate}%</span>
                          </div>
                        </div>                        
                      </TableCell>
                      <TableCell className="bg_grey text-center col-1">{d.exams}</TableCell>
                      <TableCell className="val_bg_blue text-center col-1">{Number(d.passRate).toFixed(1)}%</TableCell>                      
                      </TableRow>                      
                  )):<TableRow><TableCell colSpan={12} className='text-center'>{constants.passRate.emptyMsg}</TableCell></TableRow>}
                  {stateData.length>0 ? !isEmpty(initialData.dTRow) && distOrStateTotFormat(initialData.dTRow):""}
                </TableBody>
              </Table>
            </TableContainer>}
            {stateData.length>0 && swapStateToDistrict.current && <TableContainer>
            <Table aria-label="pass rate table" id="stateTable">
                <TableHead>                  
                  <TableRow className="h_bottom">
                    {constants.passRate.districtTableHeaders.map((t) => {
                      return(<TableCell className={t.class}>
                                  <TableSortLabel onClick={() => dataSort({key: t.col,ascending: !sort.ascending})}>
                                    {t.label === "gte" ? (<label style={{color:"#fff"}}>&ge;E</label>) : (t.label)}
                                  </TableSortLabel> 
                            </TableCell>);
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>            
                  {sDistrictData.length>0 ?!isEmpty(initialData.dTRow)&& distOrStateTotFormat(initialData.dTRow):""}
                  {sDistrictData.length>0? sDistrictData.map((d) => (                    
                      <TableRow hover={true} key={d.centreID}>                     
                      <TableCell aria-label={d.centreID + " - " + d.centreName}
                                 title={d.centreID + " - " + d.centreName}
                                 className="col-2">
                        <a className="syl_link" onClick={()=>districtToCentreSwap(d.centreID,"step2")}><span className="d-inline-block text-truncate" style={{width:"200px"}}>{d.centreID} - {d.centreName}</span></a>
                      </TableCell>
                      <TableCell className="col-8">
                        <div className="progress p_bar_rad h-100">
                          <div style={{ height: "100%", width: ((d.passRate<20 && d.passRate>0) || (smallScreen && d.passRate>0))?
                                      "auto":`${d.passRate}%` }}
                               className="progress-bar head_bg_blue bar_text"
                               role="progressbar">
                              <span className="mx-1">{d.passRate}%</span>
                          </div>
                        </div>                        
                      </TableCell>
                      <TableCell className="bg_grey text-center col-1">{d.exams}</TableCell>
                      <TableCell className="val_bg_blue text-center col-1">{Number(d.passRate).toFixed(1)}%</TableCell>                      
                      </TableRow>                      
                  )):<TableRow><TableCell colSpan={12} className='text-center'>{constants.passRate.emptyMsg}</TableCell></TableRow>}
                  {sDistrictData.length>0 ?!isEmpty(initialData.dTRow)&& distOrStateTotFormat(initialData.dTRow):""}
                </TableBody>
              </Table>
            </TableContainer>}
            {swapDistrictToCentre.current && <TableContainer>
              <Table aria-label="pass rate table">
                <TableHead>
                  <TableRow className="no_border_bottom">
                    <TableCell colSpan={2}></TableCell>
                    <TableCell colSpan={2} style={{ textAlign: "center" }} className="bg_grey">
                      School
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      National
                    </TableCell>
                  </TableRow>
                  <TableRow className="h_bottom">
                    {constants.passRate.districtBySchoolSubHeads.map((t) => {                     
                        return(<TableCell colSpan={t.colSpan} className={t.class}>
                            <TableSortLabel onClick={() => dataSort({key: t.col,ascending: !sort.ascending})}>
                              {t.label === "gtc" ? (<>&ge;C</>) : t.label === "gte" ? 
                              t.col === "passRate" ? (<label style={{color:"#fff"}}>&ge;E</label>):
                              (<>&ge;E</>) : (t.label)}
                            </TableSortLabel>
                      </TableCell>);
                      
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                {/* {!isEmpty(initialData.dTRow) && distOrStateTotFormat(initialData.dTRow)}*/}
                  {disCentres.length>0? disCentres.map((d) => (
                    <TableRow hover={true} key={d.sylID}>
                      <TableCell aria-label={d.sylID + " - " + d.sylName} 
                                 title={d.sylID + " - " + d.sylName} 
                                 className="col-2">
                        <span className="d-inline-block text-truncate" style={{width:"200px"}}>{d.sylID + " - " + d.sylName}</span>
                      </TableCell>
                      <TableCell className="col-7">
                        <div className="progress p_bar_rad h-100">
                          <div style={{ height: "100%", width: ((d.passRate<20 && d.passRate>0) || (smallScreen && d.passRate>0))?
                                        "auto":`${d.passRate}%` }}
                               className="progress-bar head_bg_blue bar_text"
                               role="progressbar">
                              <span className="mx-1">{d.passRate}%</span>
                          </div>
                        </div>                        
                      </TableCell>                      
                      <TableCell className="bg_grey text-center col-1">{d.exams}</TableCell>
                      <TableCell className="val_bg_blue text-center col-1">{Number(d.passRate).toFixed(1)}%</TableCell>
                      <TableCell className="text-center col-1">{Number(d.passRateC).toFixed(1)}%</TableCell>
                    </TableRow>
                  )):<TableRow><TableCell colSpan={12} className='text-center'>{constants.passRate.emptyMsg}</TableCell></TableRow>}
                  {/* {!isEmpty(initialData.dTRow) && distOrStateTotFormat(initialData.dTRow)} */}
                </TableBody>
              </Table>
            </TableContainer>}            
          </Card>:notificationStatus.current === "pending"?
          <Notification variant="info" className="mt-4">
            <p className="text-start mb-2"><strong>{initialData.seriesSelectedValue?.label} exams results pending</strong></p>
            <p className="text-start">View exam <a className="link" target="_blank" href="https://www.cambridgeinternational.org/usa/students/dates-and-deadlines/">results release dates</a>.</p>
            {roleLevel !== "state" && !emlState && <p className="text-start" style={{marginBottom:"10px;"}}>Pass rate reports will be available after this time.</p>}
          </Notification>:notificationStatus.current === "no data"?
          <Notification variant="info" className="mt-4 text-start">Data not available for {initialData.seriesSelectedValue.label} for the selected options</Notification>:""
          }
        </div>
      }
    </div>
  );
};
export default PassRate;
