import { useDispatch, useSelector } from "react-redux";
import LABLES from "../../common/labels";
import { constants } from "../../constants";
import { DropDown, GrpDropDown } from "../../common/Dropdown";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Notification, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@cambridgeassessment/cambridge-ui";
import { drop, isEmpty } from "lodash";
import { useReactToPrint } from "react-to-print";
import './TeacherReport.css';
import { setTabNumber } from "../../redux/slice/sideNavigation";
import { useNavigate } from "react-router-dom";
import { setSchoolsData, setSyllabusData } from "../../redux/slice/dropDownsData";
import { getSchoolsData, getStudentReportData, getSyllabusData, getSyllabusReportsData, teacherReportData } from "../../services/muleSoftServices";
import { setAlert } from "../../redux/slice/GlobalMessage";
import Spinner from "../../common/Spinner";
import { showPendingNotification } from "../../common/validations";
import { setDistrictNavs } from "../../redux/slice/passRate";


const TeacherReports = () =>{
    const userInfo = useSelector(state => state.userInfo),
          navigate = useNavigate(),
          dispatch = useDispatch(),        
          initialState = {
              spin: true,
              schoolSelectedValue:{},
              seriesSelectedValue: {},
              qualificationSelectedValue: {},
              syllabusSelectedValue: {},
              showDetails:false,
              sort:{key:"sylID"}
              },
          [initialData, setInitialData] = useState (initialState),
          savedPassResp = useSelector(state => state.pass),
          {seriesDropList,seriesLabels,districtNavs} = savedPassResp,
          {spin,schoolSelectedValue,
          seriesSelectedValue,qualificationSelectedValue,
          syllabusSelectedValue,showDetails,sort} = initialData,
          {totalLoggedInData,roleFromSAP,orgs,roleLevel,emlCentre,uid,state,
           emlDistrict,emlCentreObj,emlDistrictObj,hierarchy,district}= userInfo,
          [components, setComponents] = useState([]),
          [sylComponents, setSylComponents] = useState([]),
          [schoolRes,setSchoolRes] = useState([]),        
          seriesDropRef = useRef([]),
          selectedCentre = useRef(""),
          syllabusInitialized = useRef(false),
          spinnerRef = useRef(""),
          dropSelection = useRef(false),
          teacherRef = useRef(null),
          teacherLinkName = useRef(''),
          compHeaders = useRef([]),
          notificationStatus = useRef("pending"),
          downloadBtn = useRef(""),
          [syllabusRes,setSyllabusRes] = useState([]);

    let message;

    useEffect(()=>{
      window.scrollTo(0,0);
      let firstSeries;
      const {school,series,qualification,syllabus,
             syllabusRecords,teacherMail,teacherRecords} =  districtNavs.length?districtNavs[0].params:"";
      if(!syllabusInitialized.current){
          if(!isEmpty(seriesDropList)){
            seriesDropRef.current = seriesDropList;
            firstSeries = seriesDropList?.filter(s=>s.label === seriesLabels[0]);                        
          }         
          if(series){
            setInitialData((previousState)=>{
              return{...previousState,
                schoolSelectedValue:school,
                seriesSelectedValue:series,
                qualificationSelectedValue:qualification,
                syllabusSelectedValue:syllabus,
                showDetails:true,
                spin:false}
            })
            teacherLinkName.current = teacherMail;
            setSylComponents(syllabusRecords);
            setComponents(teacherRecords);
          }else{                    
            if(roleLevel === 'district' || emlDistrict){
              if(!districtNavs.length){
                retrieveSchools(firstSeries);
              }              
            }
            if(roleLevel === 'exams' || emlCentre || roleLevel === 'head'){
              if(!districtNavs.length){
                retrieveSyllabus(firstSeries,constants.dropDownList.qualification[0]);
              }
            }
          }
          downloadBtn.current = "Go to data downloads";          
          syllabusInitialized.current = true;
      }    
    },[]);

    message = LABLES.BANNER_INFO_EXAM_OFFICER;
    

    const selectedOption = (key, li) => {
      if((initialData[key]?.label && initialData[key].label === li?.label) ||
          schoolSelectedValue?.centreID && schoolSelectedValue.centreID === li?.centreID ||
          syllabusSelectedValue?.sylID && syllabusSelectedValue.sylID === li?.sylID){
          return;
      }
      if(roleLevel === "district" || emlDistrict ||
         roleLevel === "exams" || emlCentre ||
         roleLevel === "head" ){
        if(key === "schoolSelectedValue"){
          selectedCentre.current = li.centreID;
          retrieveSyllabus([seriesSelectedValue],"");
        }else if(key === "seriesSelectedValue"){
          retrieveSyllabus([li],"");
        }else if(key === "qualificationSelectedValue"){
          retrieveSyllabus([seriesSelectedValue],li);
        }else if(key === "syllabusSelectedValue"){
          reportData(reqParams([seriesSelectedValue],qualificationSelectedValue?.label,li.sylID,schoolSelectedValue?.centreID));
        }
      }
      else if(key === "syllabusSelectedValue"){
        if(showDetails){
          getSyllabusReport(li.sylID,seriesSelectedValue.label);
        }else{
          reportData(reqParams([seriesSelectedValue],
                             qualificationSelectedValue?.label,
                             li.sylID),"");        
        }
      }
      else if(key === "qualificationSelectedValue" && roleLevel !== "head"){
        reportData(reqParams([seriesSelectedValue],
                             li.label,syllabusSelectedValue?.sylID,""));        
      }      
      setInitialData((previousState)=>{
        return {...previousState,[key]: li, spin:true}
      })
      dropSelection.current = true;
    };

    const reqParams = (series,qual,syl,centreId) =>{ 
      let modifiedParams;    
        const {teacherReport} = constants.sideNav.roleBaseApiKeys;        
        if(roleLevel === "support" && emlCentre){
          modifiedParams = {
            centreId:emlCentreObj.centreId
          }
        }else if(roleLevel === "support" && emlDistrict){
          modifiedParams = {
            districtId:emlDistrictObj.districtId,
            roleLevel:emlDistrictObj.emlLevel
          }
        }
      
      const params = {token:totalLoggedInData.access_token,
                      roleName:roleFromSAP,
                      centreId:centreId?centreId:
                               modifiedParams?.centreId?modifiedParams.centreId:orgs[0]?.sourceSystems[0]?.orgId,
                      series:series[0].label,
                      quals:qual,
                      roleLevel:teacherReport[roleLevel],
                      syllabusId:syl,
                      rLevel:roleLevel,
                      stateId:state?state:hierarchy[0].stateId,
                      bpId:orgs[0]?.bpid,
                      districtId:modifiedParams?.districtId?modifiedParams.districtId:district};
      return params;
    }

    const tableHeaders = (recs) => {
      let uniqueComponentNames = [];
      if(recs.length>0 && recs[0].components.length>0 && !isEmpty(recs[0].components[0].comp)){
        recs.forEach((r,i) => {        
          r.components.forEach((c,i) => {          
              if(uniqueComponentNames.length){
                let newComp = uniqueComponentNames.filter(u=>u === c.comp);
                if(!newComp.length){
                  uniqueComponentNames.push(c.comp)
                }
              }else{
                  uniqueComponentNames.push(c.comp)
              }
                      
          });
        });
      }

      const headers = uniqueComponentNames.map((u, i) => {
        return (
          <TableCell
            className={i % 2 > 0 ? "col_odd_bg text-center" : "text-center"}
            key={i}
            colSpan={3}
          >
            {u}
          </TableCell>
        );
      });
      compHeaders.current = uniqueComponentNames;
      return headers;
    };

    const tableSubHeads = () => {
      const subHeaders = compHeaders.current.map((r,i)=>{
        return constants.syllabusCompCols.map((s, j) => {
          return (
            <TableCell
              className={i % 2 > 0 ? "col_odd_bg text-center comp_col_width" : "text-center comp_col_width"}
              key={j}
              colSpan={1}
            >
              {s}
            </TableCell>
          );
        });
      })      
      return subHeaders;
    }

    const componentColumnsData = (rec) => {
      const dataAsPerCompsOrder = compHeaders.current.map((h,i)=>{
        let eachCompData = rec?.components.filter(c=>c.comp === h);
          return constants.syllabusCompCols.map((s, j) => {
            if(eachCompData.length>0){
              return (
                <TableCell
                  className={i % 2 > 0 ? "col_odd_bg text-center" : "text-center"}
                  key={j + i}
                  colSpan={1}
                >
                  {rec.finalTotal === null?"-":
                  ((s.toLowerCase() === "mark" || s.toLowerCase() === "grade") && eachCompData[0][s.toLowerCase()] === null)?"-":
                  (s.toLowerCase() === "mark" && eachCompData[0][s.toLowerCase()] !== null)?
                  Number(eachCompData[0][s.toLowerCase()]).toFixed(1):
                  eachCompData[0][s.toLowerCase()]}
                </TableCell>
              );
            }else{
              return (
                <TableCell className={i % 2 > 0 ? "col_odd_bg text-center" : "text-center"} key={j + i} colSpan={1}>{"-"}</TableCell>
              );
            }
          });
        
      })
      return dataAsPerCompsOrder;
    };

    const showTeacherDetails = (rec) =>{
      teacherLinkName.current = rec.mail;
      setInitialData((previousState) => {
        return { ...previousState, showDetails:true,spin:true };
      });
      getSyllabusReport(rec.syllabus,seriesSelectedValue.label);        
    }

    const getReportData = async (series,candNum) =>{
      let stuRec;
      const {studentReport} = constants.sideNav.roleBaseApiKeys;
      const params = {token:totalLoggedInData.access_token,
                roleName:roleFromSAP,
                centreId:schoolSelectedValue?.centreID?schoolSelectedValue.centreID:
                         emlCentre? emlCentreObj.centreId:orgs[0]?.sourceSystems[0]?.orgId,
                series:series,
                rLevel:studentReport[roleLevel],
                roleLevel:'centre',
                bpId:orgs[0]?.bpid,
                districtId:userInfo.district,
                stateId:!isEmpty(userInfo.state)?userInfo.state:userInfo.hierarchy[0].stateId,
                candNumber:candNum},           
      stuReport = await getStudentReportData(params);
      if(stuReport?.error){
        console.log(stuReport.error);
      }else{
        return stuReport;
      }
             
    }
    
    const studentTabChange = async (candNum) =>{
      setInitialData((previousState) => {
        return { ...previousState, spin:true };
      });     
        let details = await getReportData(seriesSelectedValue?.label,candNum),dNavs = [];
      if(details?.data?.dataRows?.length>0){      
        const teacherViewInfo = {
          currentStudent:details.data.dataRows[0],reDirectTo:"/teacher-reports",tab:4,
          centName:schoolSelectedValue.centreName,
          series:details.data.series,
          centreId:details.data.centreID,
          districtId:userInfo.district,
          stateId:userInfo.state?userInfo.state:userInfo.hierarchy[0].stateId
        };
        dNavs.push({params:{school:schoolSelectedValue,
                            series:seriesSelectedValue,
                            qualification:qualificationSelectedValue,
                            syllabus:syllabusSelectedValue,                           
                            syllabusRecords:sylComponents,
                            teacherMail:teacherLinkName.current,
                            teacherRecords:components}})
        dispatch(setDistrictNavs({districtNavs:dNavs}));
        navigate("/student-details",{state:teacherViewInfo});
        dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:3}));
      }else{
        spinnerRef.current?.spinnerOpen();
        setInitialData((previousState) => {
          return { ...previousState, spin:false };
        });
        dispatch(setAlert({message:constants.alertMsgs.noDetail,status:"error"}));
      }   
    }

    const retrieveSyllabus = async (series,qualification) =>{
      let sortedSyllabuses = [],qual,syl;
      if(districtNavs.length){
        syl = districtNavs[0].params.syllabus;
      }
      qual = !isEmpty(qualification)? qualification :
             qualificationSelectedValue?.label?qualificationSelectedValue:
             constants.dropDownList.qualification[0];      
           
      const req = reqParams(series,qual?.label,"",selectedCentre.current),
            syllabusData = await  getSyllabusData(req);
            sortedSyllabuses = syllabusData?.data?.data.sort((a, b) => { 
              return a[sort.key] - b[sort.key]; 
            });     
        setSyllabusRes(sortedSyllabuses);          
        if(!isEmpty(sortedSyllabuses)){
          if(sortedSyllabuses.length>1){
            sortedSyllabuses.unshift({sylID:"All syllabuses",sylName:"All syllabuses"});
          }
          dispatch(setSyllabusData({syllabusData:sortedSyllabuses}));            
          setInitialData((previousState) => {
            return {
              ...previousState,
              syllabusSelectedValue:!isEmpty(syl)?syl:sortedSyllabuses[0],
              seriesSelectedValue:series[0],
              qualificationSelectedValue: qual
            };
          });
          req.syllabusId = !isEmpty(syl)?syl.sylID:sortedSyllabuses[0].sylID;
          req.quals = qual?.label;
          if(showDetails && !sylComponents.length){
            getSyllabusReport(req.syllabusId,series[0].label);
          }                                                 
        }else{
          spinnerRef.current?.spinnerOpen();
          setComponents([]);
          notificationStatus.current = showPendingNotification(series[0].label);
          setInitialData((previousState) => {
            return {
              ...previousState,
              spin:false,
              seriesSelectedValue:series[0],
              syllabusSelectedValue:{},
              qualificationSelectedValue:qual.label?qual:constants.dropDownList.qualification[0]
            };
          });
        }
        if(districtNavs.length){          
          dispatch(setDistrictNavs({districtNavs:[]}));
        }else{
          reportData(req);
        }
    }

    const getSyllabusReport = async (sylId,seriesId) => {
      let modifiedParams;
      const {syllabusReport} = constants.sideNav.roleBaseApiKeys;      
      if(roleLevel === "district" || roleLevel === "exams" || 
        roleLevel === "head" || emlDistrict || emlCentre){
        modifiedParams = {
          roleLevel:'teacher',
          centreId:districtNavs.length?districtNavs[0]?.params?.school?.centreID:
                   schoolSelectedValue?.centreID?
                   schoolSelectedValue.centreID:
                   emlCentreObj?.centreId?emlCentreObj.centreId:
                   orgs[0]?.sourceSystems[0]?.orgId
        }
      }else{
        modifiedParams = {
          centreId:schoolSelectedValue.centreID
        }
      }

      const params = {token:totalLoggedInData.access_token,
                      roleName:roleFromSAP,
                      centreId:modifiedParams?.centreId?modifiedParams.centreId:orgs[0]?.sourceSystems[0]?.orgId,
                      series:seriesId,
                      syllabusId:sylId,
                      bpId:orgs[0]?.bpid,
                      districtId:district?district:hierarchy[0].districtId,
                      stateId:!isEmpty(state)?state:hierarchy[0].stateId,
                      email:teacherLinkName.current,
                      roleLevel:modifiedParams?.roleLevel?modifiedParams.roleLevel:syllabusReport[roleLevel]},                      
      syllabus = await getSyllabusReportsData(params);      
      if(syllabus?.error){
        dispatch(setAlert({message:constants.alertMsgs[syllabus.message],status:syllabus.type}));
        spinnerRef.current?.spinnerOpen();
        setInitialData((previousState) => {
          return {...previousState,spin: false};
        });
      }else{
        let sortedRows;       
        if(syllabus?.data?.dataRows){        
            sortedRows = syllabus?.data?.dataRows?.sort((a, b) => {
              return a.candNumber - b.candNumber;
            });
          spinnerRef.current?.spinnerOpen();
          setInitialData((previousState) => {
            return {
              ...previousState,
              spin: false
            };
          });
          setSylComponents(!isEmpty(sortedRows)?sortedRows:[]);
        }
        else if(syllabus?.data?.length>0){
          let sortedComps=[];
          syllabus.data.forEach(d=>{            
            sortedComps = !sortedComps.length?d.dataRows:[...sortedComps,...d?.dataRows]
          })
          sortedComps = sortedComps.sort((a,b)=>{
            return a.candNumber - b.candNumber;
          })      
          setSylComponents(sortedComps);    
          spinnerRef.current.spinnerOpen();
          setInitialData((previousState) => {
            return {...previousState,spin: false};
          });
        }          
      }
    };

    useEffect(()=>{
      if(spin){
        spinnerRef.current.spinnerOpen();
      }
    },[spin])

    const reportData = async(params) =>{
      const res = await teacherReportData(params);
      if(res?.data && res.data?.dataRows.length>0){
        let arr = [];
        res.data.dataRows.forEach((d,i)=>{
          if(d.components.length>0){
              d.components.forEach((c,j)=>{
              let grades=[];                
                if(constants.teacherData.teacherHeaders.length>3){
                  constants.teacherData.teacherHeaders.splice(3);
                }
                for(let key in c.grades){                 
                    constants.teacherData.teacherHeaders.push(key);
                    grades.push(c.grades[key]);
                }
              let obj =  {
                syllabus:d.syllabus,
                syllabusName:d.syllabusName,
                mail:c.teacherEmail,
                grades:grades,
                components:d.components
              }
              arr.push(obj)
            })
          }                  
        })
      setComponents(arr);
      }else{
        setComponents([]);
        notificationStatus.current = showPendingNotification(params.series);
      }
      spinnerRef.current?.spinnerOpen();
      setInitialData((previousState) => {
        return { ...previousState, spin:false };
      });
    }  

    const backToReport = () =>{
      teacherLinkName.current = "";
      if(districtNavs.length){
        setComponents(districtNavs[0].params.teacherRecords);
        if(roleLevel === "district" || emlDistrict){        
          retrieveSchools([seriesSelectedValue]);        
        }else if(roleLevel === 'exams' || emlCentre || roleLevel === 'head'){
          retrieveSyllabus([districtNavs[0].params.series],districtNavs[0].params.qualification);
        }
      }
      setInitialData((previousState) => {
          return { ...previousState, showDetails:false };
      });
    }
    const downloadTeacherPdf = useReactToPrint({
      bodyClass:"m-2 p-2",
      documentTitle:'Teacher_Report',
        content: () => teacherRef.current
    });

    const tabChange = (s) => {
      if(s.category === "report"){
          dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:s.tab}));
      }else{
          dispatch(setTabNumber({managementTabNumber:s.tab,reportTabNumber:-1}));
      }
      navigate(s.path);
    };

    const retrieveSchools = async (series) =>{
      let modifiedParams;
      const {schoolList} = constants.sideNav.roleBaseApiKeys;
      
      if(roleLevel === "support" && emlCentre){
        modifiedParams = {
          centreId:emlCentreObj.centreId
        }
      }else if(roleLevel === "support" && emlDistrict){
        modifiedParams = {
          districtId:emlDistrictObj.districtId,
          roleLevel:emlDistrictObj.emlLevel
        }
      }
              
      const params = {token:totalLoggedInData.access_token,
                      roleName:roleFromSAP,
                      districtId:modifiedParams?.districtId?modifiedParams.districtId:district,
                      bpId:orgs[0]?.bpid,
                      roleLevel:schoolList[roleLevel]},
            schoolData = await  getSchoolsData(params),
            schoolArr = schoolData?.data?.data;
      if(!isEmpty(schoolArr)){
        let school = !isEmpty(schoolSelectedValue)?schoolSelectedValue:schoolArr[0];
        selectedCentre.current = school.centreID;
        retrieveSyllabus(series,qualificationSelectedValue);
        setSchoolRes(schoolArr);
        setInitialData((previousState)=>{
          return {...previousState,
                  spin:false,
                  schoolSelectedValue:school
                  }
        })
        dispatch(setSchoolsData({ schoolData:schoolArr}));         
      }
    }

    return (
      <div>
      {spin ? (
        <Spinner ref={spinnerRef} />
      ) :(
    <div ref={teacherRef}>
      <div className={`row ${showDetails?"mb-4":""}`}>
        {showDetails && <div className="col-lg-1 col-md-12 col-sm-12 txt text-start"><Button variant="outlined" onClick={backToReport}>Back</Button></div>}
        <div className="col-lg-3 col-md-12 col-sm-12 txt">
            <label className={showDetails ? "lbl_txt text-nowrap":"lbl_teach lbl text-nowrap"}>{LABLES.TEACHER_HEADER}</label>
            {showDetails && <div className="sub_txt mt-1 text-start">{teacherLinkName.current}</div>}
        </div>
        <div className={`col-md-12 col-sm-12 txt ${window.innerWidth <821?"":"teach_download"} ${showDetails?"col-lg-8":"col-lg-9"}`}>
            <Button className={`${window.innerWidth <821?"float-start":"float-end"}`} onClick={() => tabChange({tab:0,path:"/dashboard",category:"report"})}>{downloadBtn.current}</Button>
        </div>
      </div>
      {!showDetails && 
        <div className="sub_text teacherRep_sub_text mt-2">
          {LABLES.TEACHER_SUB_HEADER1}{" "}{LABLES.TEACHER_SUB_HEADER2}{" "}
            <a href={LABLES.TEACHER_SUB_HEAD_LINK} target="_blank" className="link">
              {LABLES.TEACHER_SUB_HEADER3}
            </a>.
        </div>}      
      {showDetails && roleLevel === "exams" && 
      <div className="sub_text teacherRep_sub_text">{LABLES.TEACHER_DETAILS_SUB_HEADER_EXAM_OFFICER}</div>}

      {<div className="row mb-4">
            {(roleLevel === "district" || emlDistrict) && isEmpty(teacherLinkName.current) &&
            <div className="mt-2 col-lg-3 col-md-12 col-sm-12">
              <DropDown class="text-start"
              label='SCHOOL'
              userSelectedDropdown='schoolSelectedValue'
              selectedValue={schoolSelectedValue.centreID}
              list={schoolRes.length?schoolRes:[]}
              onChange={selectedOption}
              option='centreID' value='centreName'
              mixedStr={true}/>
            </div>}
            {isEmpty(teacherLinkName.current) && <div className="mt-2 col-lg-3 col-md-12 col-sm-12">
              <GrpDropDown class="text-start" 
                           onChange={selectedOption}
                           userSelectedDropdown='seriesSelectedValue'
                           label={constants.passRate.dropDownLabels.series}
                           selectedValue={seriesSelectedValue.label} 
                           list={seriesDropRef.current}
                           option='label' value='label' />
            </div>}
            {isEmpty(teacherLinkName.current) && <div className="mt-2 col-lg-3 col-md-12 col-sm-12">
              <DropDown class="text-start" onChange={selectedOption}
                        userSelectedDropdown='qualificationSelectedValue'
                        selectedValue={qualificationSelectedValue.value}
                        label={constants.passRate.dropDownLabels.qual}
                        list={constants.dropDownList.qualification}
                        option='value' value='label' />
            </div>}
            {isEmpty(teacherLinkName.current) && <div className={`mt-2 col-md-12 col-sm-12 ${(roleLevel === "district" || emlDistrict)?"col-lg-3":"col-lg-6"}`}>
              <DropDown
                  class="text-start"
                  userSelectedDropdown='syllabusSelectedValue'
                  selectedValue={syllabusSelectedValue.sylID}
                  onChange={selectedOption}
                  label={constants.syllabusReports.dropDownLabels.qual}
                  list={syllabusRes}
                  mixedStr={true}
                  option='sylID' value='sylName'
                />
            </div>}
            <div className="col-2"></div>
      </div>}
          {!showDetails && components.length>0 &&  <Card className="card_spacing card-bg my-2" style={{height:'auto'}} > 
          <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
              <h3 className="card-header text-start mb-2">
              All teachers
            </h3>
            <p className="mb-4 text-start card-sub-head">
              {seriesSelectedValue?.label}
            </p>
              </div>
              <div className={`col-lg-6 col-md-12 col-sm-12 ${window.innerWidth <821?"mb-4 text-start":"text-end"}`}>
              <Button variant="outlined" startIcon={<svg  fill="#0076BD" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>} onClick={downloadTeacherPdf}>Download (pdf)</Button>
              </div>
            </div>        
            <TableContainer>
                <Table aria-label="teacher report table">
                    <TableHead>                    
                        <TableRow className='h_bottom'>
                            {constants.teacherData.teacherHeaders.map((h,i)=>
                                {return(<TableCell key={i}>{h}</TableCell>)})
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {components.length>0 && components.map((r,i)=>{
                                return(<TableRow hover={true} key={i}>
                                          <TableCell>{r.syllabus}</TableCell>
                                          <TableCell>{r.syllabusName}</TableCell>
                                          <TableCell><a href="#" onClick={() =>showTeacherDetails(r)} className='link'>{r.mail}</a></TableCell>
                                          {r.grades.length>0 && r.grades.map((g,j)=>{return(<TableCell key={j}>{g}</TableCell>)})}
                                       </TableRow>)
                            })}
                    </TableBody>
                </Table>
            </TableContainer>            
          </Card>}
          {notificationStatus.current === "pending" && isEmpty(components) && roleLevel !== 'state' &&
           roleLevel !== 'teacher' && !showDetails && <Notification variant="info" className="mt-4">
          <p className="text-start"><strong>{seriesSelectedValue.label} exams results pending</strong></p><p className="text-start">View exam <a className="link" target="_blank" href="https://www.cambridgeinternational.org/usa/students/dates-and-deadlines/">results release dates.</a></p>
              <p className="text-start">You will be able to view demographics reports once results have been released.</p>
          </Notification>}

          {isEmpty(components) && notificationStatus.current === "no data" && roleLevel !== 'state' && 
          roleLevel !== 'teacher' && !showDetails && <Notification variant="info" className="text-start">
            Data not available for {seriesSelectedValue?.label} for the selected options
          </Notification>}

          {isEmpty(components) && !showDetails && <Notification variant="warning" className="mt-4">
          <p className="text-start mb-2"><strong>Missing data</strong></p>
          {roleLevel === 'head' && <p className="text-start">Teacher roster data is missing. We can not provide detailed teacher reports for your school. Please contact your Exams Officer.</p>}
          {(roleLevel === 'district' || emlDistrict) && <p className="text-start">Teacher roster data is missing. You can upload this info now and boost your reports.</p>}                    
          {(roleLevel === 'district' || emlDistrict) && 
          <p style={{marginBottom:"10px;"}} className="text-start">
            <a className='custom_link' onClick={() => tabChange({tab:0,path:"/district-data",category:"management"})}>Upload your district data
            </a>
          </p>}
          {(roleLevel === 'exams' || emlCentre) && <p className="text-start">Teacher roster data is missing. We can not provide detailed teacher reports for your school.</p>}
          {roleLevel !== 'head' && roleLevel !== 'district' && !emlDistrict && 
          <p style={{marginBottom:"10px;"}} className="text-start">
            <a className='custom_link' onClick={() => tabChange({tab:1,path:"/teacher-data",category:"management"})}>Upload teacher rosters</a>
          </p>}                        
        </Notification>}
          {showDetails && <Card className="card_spacing" style={{height:'auto'}}>           
            <div className="row">
                <div className="col-6"></div>
                <div className="col-6 text-end mb-4">
                <Button variant="outlined" startIcon={<svg  fill="#0076BD" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                  <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"/></svg>} onClick={downloadTeacherPdf}>
                  Download (pdf)
                </Button>
                </div>
            </div> 
            <TableContainer>
                <Table className="t_border" style={{width:"16px"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell title={syllabusSelectedValue.sylID === "All syllabuses"? `${syllabusSelectedValue?.sylID}`:
                                            `${syllabusSelectedValue?.sylID} - ${syllabusSelectedValue?.sylName}`} 
                                      className="lh-lg d-inline-block text-truncate col_width">
                              <a>{syllabusSelectedValue.sylID === "All syllabuses"? `${syllabusSelectedValue?.sylID}`:
                              `${syllabusSelectedValue?.sylID} - ${syllabusSelectedValue?.sylName}`}
                              </a>
                            </TableCell>
                            <TableCell className="fg_bg_color text-center tot_col_width" colSpan={2}>Final grades</TableCell>
                            {sylComponents.length>0 && tableHeaders(sylComponents)}
                        </TableRow>
                        <TableRow>
                            <TableCell>Students ({sylComponents.length})</TableCell>
                            <TableCell className="fg_bg_color text-center tot_col_width" colSpan={1}>Total</TableCell>
                            <TableCell className="fg_bg_color text-center tot_col_width" colSpan={1}>Grade</TableCell>
                            {sylComponents.length>0 && tableSubHeads()}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                  {sylComponents.length>0 && sylComponents.map((c, i) => {
                    return (
                      <TableRow key={i} hover={true}>
                        <TableCell title={ c.candNumber + " - " + c.studentName } className="text-truncate col_width text-start">
                          <a className="syl_link" 
                             onClick={()=>studentTabChange(c.candNumber)}>
                          {c.candNumber + " - " + c.studentName}</a>
                        </TableCell>
                        <TableCell className="fg_bg_color text-center">
                          {c.finalTotal === null ? "-":Number(c.finalTotal).toFixed(1)}
                        </TableCell>
                        <TableCell className="fg_bg_color text-center">
                          {c.finalGrade === null ? "-":c.finalGrade}
                        </TableCell>
                        {componentColumnsData(c)}
                      </TableRow>
                    );
                  })}
                </TableBody>
                </Table>
            </TableContainer>            
          </Card>
          }
    </div>)}</div>)
}
export default TeacherReports;