const labels = {
  TITLE: "Report Maker",
  TEACHER_HEADER:"TEACHER VIEW",
  TEACHER_DETAILS_SUB_HEADER_EXAM_OFFICER:"View students, syllabus and component grades and marks.",
  TEACHER_SUB_HEADER1:"View grade distributions by syllabus and by teacher if provided.",
  TEACHER_SUB_HEADER2:"Learn more in the",
  TEACHER_SUB_HEADER3:"guide to Cambridge grades",
  TEACHER_SUB_HEAD_LINK:"https://www.cambridgeinternational.org/usa/about-cambridge-international-us/guide-to-cambridge-grades/",
  BANNER_INFO_EXAM_OFFICER:`<strong>Teacher data</strong> is missing. We can not provide detailed teacher reports for your school.
  <a href="https://www.cambridge.org/" target="_blank"><strong> Find out more</strong></a>`
};
export default labels;
