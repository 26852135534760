import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {Notification} from "@cambridgeassessment/cambridge-ui";
import Spinner from "../../../common/Spinner";
import { constants } from "../../../constants";
import { setAlert } from "../../../redux/slice/GlobalMessage";
import { uploadFileStatus } from "../../../services/muleSoftServices";
import './DistrictData.css';
import moment from "moment";
import { getAssessmentYear } from "../../../common/validations";

const DistrictData = () =>{
    const 
          userInfo = useSelector((state) => state.userInfo),
          initialState = {
            centreDetails: {},
            spin: true,
            seriesSelectedValue: {},
            qualificationSelectedValue: {value: 8,label: "A Level"}
          },
          [initialData, setInitialData] = useState(initialState),
          [distictData,setDistrictData] = useState([]),
          dispatch = useDispatch(),
          navigate = useNavigate(),
          spinnerRef = useRef(),
          yearRef = useRef(),
          initialized = useRef(false);

    useEffect(()=>{
        window.scrollTo(0,0);
        yearRef.current = getAssessmentYear();
        getDistrictData();      
    },[]);

    useEffect(()=>{
        spinnerRef.current?.spinnerOpen();
      },[initialData.spin])
    
    const getDistrictData = async () => {
        let modifiedParams;
        const {totalLoggedInData,roleFromSAP,hierarchy,roleLevel,
                emlDistrict,emlDistrictObj,orgs,uid} = userInfo;

        if(roleLevel === "support" && emlDistrict){
            modifiedParams = {
              districtId:emlDistrictObj.districtId,
              roleName:emlDistrictObj.roleName,
              roleLevel:emlDistrictObj.emlLevel
            }
        }
        const  params = {token:totalLoggedInData.access_token,
                        roleName:modifiedParams?.roleName?modifiedParams.roleName:roleFromSAP,
                        assessmentYear:yearRef.current,
                        // centreId:orgs[0]?.sourceSystems[0]?.orgId,
                        // series:[seriesId],
                        // quals:[qual],
                        bpId:orgs[0]?.bpid,
                        roleLevel:modifiedParams?.roleLevel?modifiedParams.roleLevel:constants.sideNav.roleBaseApiKeys[roleLevel],
                        districtId:modifiedParams?.districtId?modifiedParams.districtId:hierarchy[0].districtId},
        districtDetails = await uploadFileStatus(params.token,params,params.roleName);
        if(districtDetails?.error){
          dispatch(setAlert({message:constants.alertMsgs[districtDetails.message],status:districtDetails.type}));
          spinnerRef.current?.spinnerOpen();
          setInitialData((previousState)=>{
            return {...previousState,spin:false}
          })
        }else{         
          setDistrictData(districtDetails.data);
          spinnerRef.current?.spinnerOpen();
          setInitialData((previousState)=>{
            return {...previousState,spin:false}
          })       
          }
    }

    const renderStudentNotificaton = (e)=>{
      
        const notifyObj = {
            "processing":{ varientVal:"warning",titleVal: "Student data being processed",content:"This usually takes one hour"},
            "Complete":{varientVal:"success",titleVal: "Student data",content:""},
            "error":{varientVal:"error",titleVal: "Student data - Errors found",content:"Download validation file"}
        }
       
        const resultObj = notifyObj[e.studentUploadStatus]
        

        return(<Notification variant={resultObj?.varientVal} className="districtCls p-0" title={resultObj?.titleVal}>
        <div className="">
             <p className="tip m-0">Uploaded {moment(e.studentUploadTimestamp).format('Do MMM YYYY, h:mm a')}</p>
              <a href="#" 
                 onClick={()=>{navigate("/student-data", { state: { centreId: e.centreId,redirectTo:'/district-data' } })}} 
                 style={{ fontWeight:"600"}} 
                 className="link text-start">Upload student data</a>
          
          </div>
          </Notification>)
    }

    const renderTeacherNotificaton = (e)=>{
      
        const notifyTechObj = {
            "processing":{ varientVal:"warning",titleVal: "Teacher data being processed",content:"This usually takes one hour"},
            "Complete":{varientVal:"success",titleVal: "Teacher data",content:""},
            "error":{varientVal:"error",titleVal: "Teacher data - Errors found",content:"Download validation file"}
        }
       
        const resultTechObj = notifyTechObj[e.teacherUploadStatus]
        

        return(<Notification variant={resultTechObj?.varientVal} className="districtCls p-0" title={resultTechObj?.titleVal}>
        <div>
        <p className="tip m-0">Uploaded {moment(e.teacherUploadTimestamp).format('Do MMM YYYY, h:mm a')}</p>
            {(resultTechObj?.content)?resultTechObj.content:<a href="#" 
            onClick={()=>{navigate("/teacher-data", { state: { centreId: e.centreId,redirectTo:'/district-data' } })} } 
            style={{ fontWeight:"600"}} 
           className="link text-start">Upload teacher data</a>}
           </div>
          </Notification>)
    }

    return(
        <>
        {initialData.spin?<Spinner ref={spinnerRef} />:
         <div>
            <div className="mt-4">
                <div className="lbl district_lbl">UPLOAD DISTRICT DATA</div>
                <div className="sub_text pt-2">Upload student and teacher data for {yearRef.current} exams series</div>
            </div>
            {window.innerWidth>767 && distictData.length>0 ? distictData.map((e,i)=>{
                return(                
                    <div className={`row cardCustom ${i>0?"mt-2":"mt-3"}`} style={{margin:"inherit"}} key={i}>
                        <div className="col divider p-3">
                            <h3 className="centre mb-0 text-start"><strong>{e.centreId}</strong></h3>
                            <p className="text-start">{e.centreName}</p>
                        </div>
                        <div className="col divider p-3">
                        {(e.studentUploadStatus)? renderStudentNotificaton(e):
                        <div className="text-center py-2">
                                <a href="#" 
                                   onClick={()=>{navigate("/student-data", { state: { centreId: e.centreId,centreName:e.centreName, redirectTo:'/district-data' } })}} 
                                   style={{ fontWeight:"600"}} 
                                   className="link">Upload student data</a>
                            <p className="tip text-sm-center">No data uploaded</p>
                            </div>}
                        </div>
                        
                        <div className="col p-2 m-0">
                        { (e.teacherUploadStatus)?renderTeacherNotificaton(e):
                            <div className="text-center py-2"> 
                                <a href="#" 
                                   onClick={()=>{navigate("/teacher-data", { state: { centreId: e.centreId,centreName:e.centreName, redirectTo:'/district-data' } })} } 
                                   style={{ fontWeight:"600"}} 
                                   className="link">Upload teacher data</a>
                            
                            <p className="tip text-sm-center">No data uploaded</p></div> }
                        </div>
                        
                    </div>
                )}):<div className='text-center'></div>
            }
            {window.innerWidth<768 && distictData.length>0 ? distictData.map((e,i)=>{
                return(                
                    <div className={`cardCustom p-3 ${i>0?"mt-2":"mt-3"}`} key={i}>
                        <h3 className="centre mb-0 text-start"><strong>{e.centreId}</strong></h3>
                        <p className="text-start">{e.centreName}</p>
                        <div className="divider_resp"></div>
                        {(e.studentUploadStatus)? renderStudentNotificaton(e):
                        <div className="text-start py-2">
                                <a href="#" 
                                   onClick={()=>{navigate("/student-data", { state: { centreId: e.centreId,centreName:e.centreName, redirectTo:'/district-data' } })}} 
                                   style={{ fontWeight:"600"}} 
                                   className="link">Upload student data</a>
                            <p className="tip text-start">No data uploaded</p>
                            </div>}
                        <div className="divider_resp"></div>
                        { (e.teacherUploadStatus)?renderTeacherNotificaton(e):
                            <div className="text-start py-2"> 
                                <a href="#" 
                                   onClick={()=>{navigate("/teacher-data", { state: { centreId: e.centreId,centreName:e.centreName, redirectTo:'/district-data' } })} } 
                                   style={{ fontWeight:"600"}} 
                                   className="link">Upload teacher data</a>
                            
                            <p className="tip text-start">No data uploaded</p></div> }                        
                    </div>
                )}):<div className='text-center'></div>
            }
        </div>}
        </>
    )   
}
export default DistrictData;